<template>

  <div><h1 class="heading mb-5">{{ newsDetailData.title }}</h1></div>

  <text-block>
    <div v-html="newsDetailData.detail_text"></div>
  </text-block>
  <secondary-button class="back-to-list ml-2" @click="$router.push({ name: 'NewsList' })">
    <div class="back-to-list">
        <span class="material-icons">
        west
        </span>
      Назад
    </div>
  </secondary-button>
</template>

<script>

export default {
  name: "NewsDetail",
  // data () {
  //   return {
  //     //
  //   }
  // },
  mounted() {
    this.$store.dispatch("newsDetailData", {id: this.$route.params.id});
  },
  methods: {
  },
  computed: {
    newsDetailData() {
      return this.$store.getters.getNewsDetailData;
    },
  }
}
</script>

<style scoped>
.back-to-list {
  display:flex !important;
  align-items: center !important;
  justify-content: center;
}
.material-icons {
  padding-right:10px;
}
#app .back-to-list {
  width: 150px;
}
</style>